import React, { useState } from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"

import { Box, Flex, Heading, Text } from "components"

function ListItem({ data, uid }) {
  const [isHovering, setIsHovering] = useState(false)
  return (
    <Flex
      alignItems="center"
      mb="layout.2"
      pb="layout.2"
      borderBottom={"1px solid"}
      borderColor="wash"
    >
      <Box pr={"layout.2"} width="80%">
        <Link
          to={"/news/" + uid + "/"}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          className={isHovering === true ? "isHovering" : ""}
          css={css({
            transition: "color 300ms ease-in-out",
            ".arrow--after": {
              opacity: 0,
              transform: "translateX(0)",
              transition: "transform 400ms ease-out, opacity 200ms ease-out",
            },
            "&.isHovering": {
              color: "primary",
              ".arrow--after": {
                opacity: 1,
                transform: "translateX(8px)",
              },
            },
          })}
        >
          <Flex alignItems="center">
            <Text children={data.title.text} size={500} color="inherit" />
            <Box
              className="arrow--after"
              as="svg"
              width={(18 / 14) * 12}
              height={12}
              viewBox="0 0 18 14"
              fill="none"
              color="primary"
              ml={"layout.1"}
            >
              <title>Arrow Right</title>
              <path
                stroke="currentColor"
                strokeWidth={2}
                d="M0 7h16M10 13l6-6-6-6"
              />
            </Box>
          </Flex>
        </Link>
        {data.pdf && (
          <Box display="inline-block" mt="layout.1">
            <Box
              as="a"
              href={data.pdf.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Heading
                children="PDF"
                as="span"
                size={600}
                display="block"
                px="layout.1"
                py="spacing.2"
                color="background"
                bg="primary"
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box width={["25%", "20%"]}>
        <Text children={data.date} color="alt" textAlign="right" />
      </Box>
    </Flex>
  )
}

export default ListItem
