import React, { useState } from "react"
import { Link } from "gatsby"
import Image from "gatsby-image/withIEPolyfill"
import css from "@styled-system/css"

import { Box, ColorMode, Flex, Heading, Text } from "components"

const Card = ({ data, index, uid }) => {
  const [isHovering, setIsHovering] = useState(false)

  return (
    <ColorMode mode="dark">
      <Box
        className={isHovering === true ? "isHovering" : ""}
        height="100%"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        css={css({
          ".card--image": {
            transition: "transform 200ms ease-out",
            transform: "scale(1)",
          },
          ".card--contents": {
            transformOrigin: "left",
            transform: "translate3d(0,0,0)",
            transition: "transform 200ms ease-out",
          },
          ".card--contents-vertical": {
            transformOrigin: "top",
            transform: "translate3d(0,0,0)",
            transition: "transform 200ms ease-out",
          },
          "&.isHovering": {
            ".card--image": {
              transform: "scale(1.05)",
            },
            ".card--contents": {
              transform: "translate3d(-12px,0,0)",
            },
            ".card--contents-vertical": {
              transform: "translate3d(0,-12px,0)",
            },
          },
        })}
      >
        <Link to={"/news/" + uid + "/"}>
          <Box height="100%" bg="background" overflow="hidden">
            <Flex
              className={
                index === 0 ? "card--contents" : "card--contents-vertical"
              }
              flexDirection={["column", index === 0 ? "row" : "column"]}
            >
              <Box width={["100%", index === 0 ? "66.667%" : "100%"]}>
                <Box
                  position="relative"
                  pb={[
                    0.5625 * 100 + "%",
                    index === 1 ? 0.8510638298 * 100 + "%" : 0.5625 * 100 + "%",
                  ]}
                  overflow="hidden"
                >
                  <Image
                    className={"card--image"}
                    fluid={data.hero_image.fluid}
                    alt={data.hero_image.alt}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    objectFit="cover"
                    objectPosition="center"
                  />
                </Box>
              </Box>
              <Flex
                position="relative"
                flexDirection="column"
                flex={1}
                pt="layout.3"
                px="layout.2"
                pb="layout.2"
                bg="wash"
              >
                {/* News box headline */}
                <Heading
                  children={data.title.text}
                  size={600}
                  fontSize={[16, null, index === 0 ? 24 : 16]}
                  color="text"
                />
                {/* News box date */}
                <Text
                  children={data.date}
                  size={300}
                  mt="auto"
                  pt={["layout.1", "layout.2"]}
                  color="alt"
                />

                {/* PDF text at bottom of box */}
                {data.pdf && (
                  <Box
                    as="a"
                    href={data.pdf.url}
                    position="absolute"
                    bottom="spacing.4"
                    right="spacing.4"
                  >
                    <Heading
                      children="PDF"
                      as="span"
                      size={600}
                      display="block"
                      px="layout.1"
                      py="spacing.2"
                      color="text"
                      bg="primary"
                    />
                  </Box>
                )}
              </Flex>
            </Flex>
          </Box>
        </Link>
      </Box>
    </ColorMode>
  )
}

export default Card
