import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import css from "@styled-system/css"
import moment from "moment"

import Card from "./_card"
import ListItem from "./_listItem"

import {
  Box,
  Flex,
  Heading,
  FadeWrapper,
  SEO,
  SlideWrapper,
  Wrapper,
} from "components"

function NewsPage() {
  const { page, news } = useStaticQuery(PAGE_QUERY)

  // Sort the News Items into an array of years.

  const [years, setYears] = useState([])

  const sortYears = () => {
    let uniqueYears = []
    let yearsWithNews = []

    news.nodes.forEach((item, index) => {
      if (item.data.date) {
        // Get this item's year in YYYY format.
        let newsItemYear = moment(item.data.date).year()

        // See if this item's year exists as a year in the uniqueYears array.
        // If it doesn't, push this value to the array and create a year object in
        // the yearsWithNews array.
        if (uniqueYears.indexOf(newsItemYear) === -1) {
          uniqueYears.push(newsItemYear)
          yearsWithNews.push({ year: newsItemYear, items: [] })
        }

        // Push this item to the proper yearsWithNews array.
        yearsWithNews.forEach((newsYear, index) => {
          if (newsYear.year === newsItemYear) {
            newsYear.items.push(item)
          }
        })
      }
    })
    setYears(yearsWithNews)
  }

  useEffect(() => {
    sortYears()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [news])

  // Shorthand function to dynamically create a grid of news items
  const calculateWidth = index => {
    let width = "50%"
    if (index === 0) {
      width = "100%"
    }
    if (index === 1) {
      width = "40%"
    }
    if (index === 2) {
      width = "60%"
    }
    return width
  }

  return (
    <>
      <SEO
        title={page.data.seo_title && page.data.seo_title.text}
        description={
          page.data.seo_description && page.data.seo_description.text
        }
        imageOpenGraph={page.data.seo_image && page.data.seo_image.url}
        imageTwitter={page.data.seo_image && page.data.seo_image.url}
        imageAlt={page.data.seo_image && page.data.seo_image.alt}
      />
      {/* News page heading */}
      <FadeWrapper>
        <>
          <Wrapper pt={["128px", "196px"]} mb="layout.6">
            <Box mb="layout.6">
              <Heading children={page.data.subtitle.text} size={900} />
            </Box>
          </Wrapper>
          {/* News Items sorted by year */}
          <Wrapper>
            {years.map((year, index) => {
              if (year.year > new Date().getFullYear() - 3) {
                return (
                  <Box key={"year" + index} my={["layout.4", "layout.6"]}>
                    <Heading
                      size={800}
                      children={year.year}
                      mb={["layout.2", "layout.4"]}
                    />
                    <Box mx={[-16, 0]}>
                      <Flex flexWrap="wrap" m="-8px">
                        {year.items.map(({ data, uid }, itemIndex) => (
                          <Box
                            css={css({
                              width: ["100%", calculateWidth(itemIndex)],
                              p: "8px",
                              "> *": {
                                height: "100%",
                                "> *": {
                                  height: "100%",
                                },
                              },
                            })}
                          >
                            <SlideWrapper>
                              {/* News Items sorted by year */}
                              {itemIndex === 0 && (
                                <>
                                  <Box display={["none", "block"]}>
                                    <Card
                                      data={data}
                                      index={itemIndex}
                                      uid={uid}
                                      key={"year" + index + "item" + itemIndex}
                                    />
                                  </Box>
                                  <Box display={["block", "none"]}>
                                    <Card
                                      data={data}
                                      index={itemIndex + 1}
                                      uid={uid}
                                      key={"year" + index + "item" + itemIndex}
                                    />
                                  </Box>
                                </>
                              )}
                              {itemIndex !== 0 && (
                                <Card
                                  data={data}
                                  index={itemIndex}
                                  uid={uid}
                                  key={"year" + index + "item" + itemIndex}
                                />
                              )}
                            </SlideWrapper>
                          </Box>
                        ))}
                      </Flex>
                    </Box>
                  </Box>
                )
              } else {
                return (
                  <Box key={"year" + index} my={["layout.2", "layout.6"]}>
                    <Heading
                      size={800}
                      children={year.year}
                      mb={["layout.2", "layout.4"]}
                    />

                    <Flex
                      flexWrap="wrap"
                      m="-4px"
                      css={css({
                        "> *": {
                          width: ["100%"],
                          p: "4px",
                        },
                      })}
                    >
                      {year.items.map(({ data, uid }, itemIndex) => (
                        <ListItem
                          data={data}
                          uid={uid}
                          key={"year" + index + "item" + itemIndex}
                        />
                      ))}
                    </Flex>
                  </Box>
                )
              }
            })}
          </Wrapper>
        </>
      </FadeWrapper>
    </>
  )
}

const PAGE_QUERY = graphql`
  {
    page: prismicNews {
      data {
        seo_title {
          text
        }
        seo_description {
          text
        }
        seo_image {
          url
          alt
        }
        subtitle {
          text
        }
        subtitle_text_box {
          text
        }
        title {
          text
        }
      }
    }
    news: allPrismicNewsItem(sort: { order: DESC, fields: data___date }) {
      nodes {
        uid
        data {
          pdf {
            url
          }
          date(formatString: "MMM DD, YYYY")
          title {
            text
          }
          hero_image {
            fluid(maxWidth: 1200) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
  }
`

export default NewsPage
